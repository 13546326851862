<template>
  <div class='h-full bg-white overflow-y-auto relative py-8 max-w-7xl mx-auto'>
    <button class='z-30 absolute top-3 right-3' @click="$modal.hide('search-modal')">
      <x-icon class='text-black' />
    </button>
    <div class='mt-4' style='height: calc(100% - 1rem);'>
      <div class='px-4'>
        <input v-model='searchString' type='search' class='border rounded-md border-gray-200 px-4 py-3 w-full'
          placeholder='Search' @keyup.enter='submitSearchString'>
      </div>
      <div v-if='hasSearchResults' class='overflow-y-auto mt-2 pl-4 pr-2' style='height: calc(100% - 3.5rem);'>
        <session-details-full v-for='session in matchingSessions'
          :key='`matching-${searchString}-session-${session.id}`' :session='session' :highlight-string='searchString'
          :repeatedComponent='true' class='mt-4 mb-12' />
      </div>
      <div v-else-if='emptySearchResults'>
        <div class='text-center mt-4 mx-4 bg-red-50 rounded-md p-16'>
          <div class='text-lg font-bold'>No results found</div>
          <div class='text-gray-600'>Try searching for something else</div>
        </div>
      </div>
      <div v-else>
        <div class='text-center mt-4 mx-4 bg-gray-50 rounded-md p-16'>
          <div class='text-lg font-normal'>
            Please enter a search string for a session based on the session title, speaker, or speaker topic.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import debounce from 'debounce'
import SessionDetailsFull from '@/components/sessions/SessionDetailsFull.vue'

export default {
  name: 'Search',
  data () {
    return {
      searchString: '',
      matchingSessions: [],
    }
  },
  components: {
    SessionDetailsFull,
  },
  watch: {
    searchString: function (newSearchString) {
      if (newSearchString.length > 0) {
        this.submitSearchString()
      }
    },
  },
  computed: {
    hasSearchResults () {
      return this.searchString && 
             this.matchingSessions.length > 0
    },
    emptySearchResults () {
      return this.searchString && 
             this.matchingSessions.length === 0
    },
  },
  methods: {
    ...mapActions('sessions', [
      'searchSessions',
    ]),
    submitSearchString () {
      this.searchSessions(this.searchString).then(resp => {
        this.matchingSessions = resp
      })
    }
  },
  mounted () {
    this.submitSearchString = debounce(this.submitSearchString, 500)
  },
}
</script>